import React from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";

import {
  Container,
  Wrapper,
  Row,
  Box,
  Button,
  FlexButtons,
} from "../../components/util";
import { CallToAction, CustomerQuote } from "../../components/site";

import Image from "../../components/image";
import Layout from "../../components/layout";
import Seo from "../../components/seo";

import Logo from "../../images/logos/Droppah/Droppah_Full_White.svg";

import PlayLeft from "../../images/graphics/rebrand/Play_Left.svg";
import PlayRight from "../../images/graphics/rebrand/Play_Right.svg";
import Goals from "../../images/graphics/rebrand/Goals.svg";
import Time from "../../images/graphics/rebrand/Time.svg";
import Balance from "../../images/graphics/rebrand/Balance.svg";
import Juggle from "../../images/graphics/rebrand/Juggle.svg";
import Upskill from "../../images/graphics/rebrand/Upskill.svg";

const HeroContainer = styled(Container)`
  .wrapper {
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      padding-top: 40px;
      padding-bottom: 40px;
    }

    h1 {
      @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
        padding: 0 100px;
      }

      @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        padding: 0;
      }
    }
  }

  > img {
    position: absolute;
    width: 22%;
    top: 50%;
    transform: translateY(-50%);

    &.-left {
      left: -60px;
    }

    &.-right {
      right: -60px;
    }

    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      width: 140px;
      top: unset;
      transform: translateY(0);
      top: -10px;
    }
  }
`;

const BenefitList = styled(Row)`
  --item-gap: 30px;
  align-items: stretch;

  width: calc(100% + var(--item-gap));

  > div {
    width: calc((100% / 2) - var(--item-gap));
    margin-right: var(--item-gap);
    margin-bottom: var(--item-gap);

    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      width: 100%;
      margin-right: 0;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
  }
`;

const BenefitContent = styled(Row)`
  padding: 40px;
  flex-direction: column;
  transition: transform 0.25s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }

  &.-orange {
    background-color: ${(props) => props.theme.colours.orange};
  }

  &.-rose {
    background-color: ${(props) => props.theme.colours.rose};
  }

  &.-deeppurple {
    background-color: ${(props) => props.theme.colours.deeppurple};
  }

  &.-emerald {
    background-color: ${(props) => props.theme.colours.emerald};
  }

  &.-cherry {
    background-color: ${(props) => props.theme.colours.cherry};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    text-align: left !important;
  }
`;

const Benefits = [
  {
    benefit: "Rosters like no other",
    description:
      "Use Droppah’s roster making app to find the right coverage and save your hard-earnt cash. Add, edit & swap shifts to get the roster right.",
    illustration: Time,
    image: "Droppah_Calendar_DragNDrop.png",
    benefit_colour: "-orange",
  },
  {
    benefit: "Hospo management made easy",
    description:
      "Easily manage comms with an employee mobile app. Thanks to a delish work roster app, get all the cooks in the kitchen with simplified roster sharing & shift swapping.",
    illustration: Juggle,
    image: "Droppah_Live_Break.png",
    benefit_colour: "-rose",
  },
  {
    benefit: "Online time clock fit for the bartender, barista and sous chef",
    description:
      "Get employees clocked in and out, in less time, with Droppah’s staff rostering software. Keep tabs on what your crew are up to with the live view.",
    illustration: Upskill,
    image: "Droppah_Preview_Timeclock.png",
    benefit_colour: "-deeppurple",
  },
  {
    benefit: "Easy hospo timesheets that take less of your time",
    description:
      "Wrap up the rostering process and never miss a staff timesheet again. Simplify roster automation by approving timesheets and sending them to payroll.",
    illustration: Goals,
    image: "Droppah_Calendar_Coverage.png",
    benefit_colour: "-emerald",
  },
];

const SchedulingSoftwareLP = (props) => {
  return (
    <Layout fullPage>
      <Seo
        title="Droppah | Rosters that don't suck"
        description="Suss your schedule sitch in a sec with Droppah, staff scheduling, time tracking & attendance software for your business."
        pathname={props.location.pathname}
      />
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <HeroContainer>
        <img className="-left" src={PlayLeft} />
        <img className="-right" src={PlayRight} />
        <Wrapper className="wrapper" stackGap={100}>
          <Box stackGap={60}>
            <img
              className="-center"
              src={Logo}
              alt="Droppah Logo"
              height="40"
              css={{ maxWidth: "30%" }}
            />
            <Box stackGap={40}>
              <Box className="-textCenter">
                <h1 css={{ fontSize: "6rem" }} className="-center">
                  Rosters that don’t suck
                </h1>
                <h4>
                  Scheduling software that makes short order of rostering.{" "}
                </h4>
              </Box>
              <Box stackGap={15}>
                <Button className="-xl gtm-cta -center primary" to="/signup">
                  Let's Go
                </Button>
                <p
                  css={{ fontSize: "1rem", opacity: "0.5" }}
                  className="-textCenter"
                >
                  Free rostering software for up to 10 employees
                </p>
              </Box>
            </Box>
            <Image
              filename="Droppah_CustomerLogos.png"
              alt="Droppah Customer Logos"
              maxWidth={850}
              centerImage
            />
          </Box>
        </Wrapper>
      </HeroContainer>
      <Container css={{ overflow: "visible" }}>
        <Wrapper stackGap={1}>
          <BenefitList isWrap justify="flex-start">
            {Benefits.map((item, i) => {
              return (
                <BenefitContent className={item.benefit_colour}>
                  <Box stackGap={20} css={{ marginBottom: "30px" }}>
                    <img src={item.illustration} height="110" />
                    <Box>
                      <h3>{item.benefit}</h3>
                      <p>{item.description}</p>
                    </Box>
                  </Box>
                  <Box size={100}>
                    <Image
                      filename={item.image}
                      alt={`Droppah | ` + item.benefit}
                      centerImage
                      addShadow
                    />
                  </Box>
                </BenefitContent>
              );
            })}
          </BenefitList>
        </Wrapper>
      </Container>
      <Container>
        <Wrapper>
          <CustomerQuote
            quote="With Droppah, rostering takes two quick steps at the end of the week and we can see exactly where our money is being spent."
            name="Lea Winzer"
            company="Man O' War"
            pic="ManOWar_Logo_White.png"
            center
            large
          />
        </Wrapper>
      </Container>
      <CallToAction
        title="Get ready for service in less than two minutes with easy peasy hospo rostering software."
        buttonText="Start For Free Today"
        colour="#136841"
        smallerText
        hideDemoButton
      />
    </Layout>
  );
};

export default SchedulingSoftwareLP;
